import { useState } from "react";
import "./App.css";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const apiUrl = process.env.REACT_APP_GRAPHQL_URL;
const query = `
  mutation SignIn($emailOrUsername: String!, $password: String!) {
    signIn(emailOrUsername: $emailOrUsername, password: $password)
  }
`;
const params = new URLSearchParams(window.location.search);

function App() {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  const [redirectTo] = useState(
    params.get("redirect") || process.env.REACT_APP_PLE_TOOLS_BASE_URL
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    setMessage("");

    if (!username || !password) {
      setMessage("Please enter a username and password.");
      return;
    }

    setLoading(true);

    if (process.env.NODE_ENV === "development") {
      // Simulate a delay for the API request
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { emailOrUsername: username, password },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          setMessage(data.errors[0].message);
          setLoading(false);
        } else {
          const token = data.data.signIn;

          // get expiration date of token
          const decoded = jwtDecode(token);

          // set token in cookie
          Cookies.set("token", token, {
            expires: new Date(decoded.exp * 1000),
            path: "/",
            sameSite: "strict",
            secure: process.env.NODE_ENV === "production",
          });

          if (process.env.NODE_ENV === "production") {
            window.location.href = redirectTo;
          } else {
            setMessage("Redirecting works in production only.");
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setMessage("An error occurred. Please try again.");
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <div className="app">
      <form>
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          onChange={(e) => setUsername(e.target.value)}
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <button
          className={loading ? "button-loading" : ""}
          id="login-submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          <span className="button-text">Log In</span>
        </button>
        <div id="message">{message}</div>
      </form>
    </div>
  );
}

export default App;
